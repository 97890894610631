import {
  isLoggedIn,
  checkLocalStorage,
  removeCardFromLocalStorage,
} from "./localStorage";

const cardsListContainer = document.querySelector(".cards-list-container");
const cardsData = checkLocalStorage();

const cardsList = async function () {
  if (location.pathname !== "/my-cards" || !cardsListContainer) return;
  
  const loggedIn = await isLoggedIn();

  if (loggedIn) return;

  if (cardsData && cardsData.length !== 0) {
    cardsListContainer.removeChild(cardsListContainer.querySelector(".loading"));

    cardsData.forEach((cardDataObj) => {
      const cardStoreId = cardDataObj.cardStoreId;
      const cardStoreName = cardDataObj.cardStoreName;
      const cardStoreSlug = cardDataObj.cardStoreSlug;
      const cardStoreLogo = cardDataObj.cardStoreLogo;
      const cardsArray = cardDataObj.cards;

      const cardElement = document.createElement("div");
      cardElement.classList.add("card", "mb-3");

      cardElement.innerHTML = `
        <div class="card-body">
          <div class="d-flex align-items-center">
            ${
              cardStoreLogo 
              ? `<img src="/uploads/${cardStoreLogo}" alt="${cardStoreName}" title="${cardStoreName}" width="45" height="45" />`
              : `<span class="position-relative rounded-3" style="width: 45px; height: 45px; background-color: #e9ecef"><i class="position-absolute top-50 start-50 translate-middle bi bi-image text-white"></i></span>`
            }
            <div class="ms-3">
              <small class="text-muted">Картки закладу</small>
              <h5 class="m-0">${cardStoreName}</h5>
            </div>
          </div>
        </div>
      `;

      const listGroup = document.createElement("ul");
      listGroup.classList.add("list-group", "list-group-flush");

      cardsArray.forEach((card) => {
        listGroup.innerHTML += `
        <li class="list-group-item d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <a href="/${cardStoreSlug}/${card.cardId}" class="text-decoration-none fs-1" style="width: 40px">${String.fromCodePoint(card.cardBonusIcon)} </a>
            <div>
              <h6 class="m-0"><a href="/${cardStoreSlug}/${card.cardId}" class="text-decoration-none">${card.cardTypeName}</a></h6>
              <p class="text-muted mb-0"></p>
              <a
                class="text-decoration-none link-secondary"
                href="/${cardStoreSlug}/${card.cardId}"
                >Бонусів: ${card.cardAmount} з ${card.cardBonusAmount}</a
              >
            </div>
          </div>
          <a
            class="btn btn-outline-danger btn-sm remove-card" id="${card.cardId}" data-store-id="${cardStoreId}" role="button"><i class="bi bi-trash3"></i></a>
        </li>
        `;

        cardElement.appendChild(listGroup);
      });

      cardsListContainer.appendChild(cardElement);

      document
        .querySelectorAll(`a.btn.remove-card`).forEach((card) => {
          card.addEventListener("click", (e) => {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            removeCardFromLocalStorage(card.id, card.dataset.storeId);
          });
        })
    });
  } else {
    cardsListContainer.removeChild(cardsListContainer.querySelector(".loading"));

    const cardElement = document.createElement("div");
    cardElement.classList.add("card", "mb-3");

    cardElement.innerHTML = `
      <div class="card-body">Тут відображатимуться ващі майбутні картки.</div>
    `;

    cardsListContainer.appendChild(cardElement);
  }
};

export default cardsList;
