function formValidate(form) {
  if (!form) return;

  const validateOptions = [
    {
      attribute: "minlength",
      isValid: (input) => input.value.length >= parseInt(input.minLength, 10),
      errorMessage: (input, label) =>
        `${label.textContent.split(" *")[0]} має бути не меньше ${
          input.minLength
        } символів`,
    },
    {
      attribute: "pattern",
      isValid: (input) => {
        const regEx = new RegExp(input.pattern);
        return regEx.test(input.value);
      },
      errorMessage: (input, label) =>
        `Поле ${
          label.textContent.split(" *")[0]
        } має бути вказано у правильному форматі`,
    },
    {
      attribute: "match",
      isValid: (input) => {
        const matchSelector = input.getAttribute("match");
        const matchedEl = form.querySelector(`#${matchSelector}`);
        return matchedEl && matchedEl.value.trim() === input.value.trim();
      },
      errorMessage: (input, label) => {
        const matchSelector = input.getAttribute("match");
        const matchedEl = form.querySelector(`#${matchSelector}`);
        const matchedLabel =
          matchedEl.parentElement.parentElement.querySelector("label");
        return `Поле ${
          label.textContent.split(" *")[0]
        } має співпадати з полем ${matchedLabel.textContent.split(" *")[0]}`;
      },
    },
    {
      attribute: "workdays",
      isValid: (input) => !input.options[input.selectedIndex].disabled,
      errorMessage: (input, label) => `Оберіть відповідний час`,
    },
    {
      attribute: "required",
      isValid: (input) => input.value.trim() !== "",
      errorMessage: (input, label) =>
        `${label.textContent.split(" *")[0]} обовʼязкове поле`,
    },
  ];

  const validateSingleFormGroup = (formGroup) => {
    if (!formGroup) return;

    const label = formGroup.querySelector("label");
    const input = formGroup.querySelector("input, textarea, select");
    const errorContainer = formGroup.querySelector(".invalid-feedback");

    let formGroupError = false;

    for (const option of validateOptions) {
      if (input.hasAttribute(option.attribute) && !option.isValid(input)) {
        errorContainer.textContent = option.errorMessage(input, label);
        input.classList.add("is-invalid");
        formGroupError = true;
      }
    }

    if (!formGroupError) {
      errorContainer.textContent = "";
      input.classList.remove("is-invalid");
    }
  };

  form.setAttribute("novalidate", "");

  const updateSubmitButtonState = (form, el) => {
    const submitButton = form.querySelector("[type='submit']");

    let isDisabled = true;

    if (el.type === "checkbox") {
      const initialChecked = el.getAttribute("initial") === "true";
      if (el.checked !== initialChecked) {
        isDisabled = false;
      }
    } else if (el.value !== el.getAttribute("initial")) {
      isDisabled = false;
    } else {
      isDisabled = true;
    }

    submitButton.disabled = isDisabled;
  };

  Array.from(form.elements).forEach((element) => {
    if (element.tagName !== "BUTTON") {
      // Edit form submit state
      if (form.id === "account" || form.id === "cardTypeForm") {
        if (element.type === "checkbox") {
          element.setAttribute("initial", element.checked);
        } else {
          element.setAttribute("initial", element.value);
        }

        element.addEventListener("change", (event) => {
          event.preventDefault();
          event.stopPropagation();
          updateSubmitButtonState(form, event.target);
        });
        
        const addAddressBtn = form.querySelector(".add-address");
        
        addAddressBtn?.addEventListener(
          "click",
          (e) => {
            e.preventDefault();
            e.stopPropagation();
            form.querySelector("[type='submit']").disabled = false;
          }
        );
      }
      element.addEventListener("blur", (event) => {
        validateSingleFormGroup(event.target.closest(".form-group"));
      });
    }
  });

  form.addEventListener("submit", (event) => {
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();

      validateAllFormGroups(form);
    } else {
      event.submitter.disabled = true;
    }
  });

  const validateAllFormGroups = (formToValidate) => {
    const formGroups = Array.from(
      formToValidate.querySelectorAll(".form-group")
    );

    formGroups.forEach((formGroup) => {
      validateSingleFormGroup(formGroup);
    });
  };
}

export { formValidate };
