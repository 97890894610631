import "../styles/style.css";

import { setLocalStorage } from "./modules/localStorage";
import cardsList from "./modules/cardsList";
import { formValidate } from "./modules/formValidate";
import {
  phoneMask,
  qrcodeColorEditor,
  locationAutocomplete,
  addAnAddress,
  clearAddressFields,
  // singleBonusIcon,
  showHide,
  saturdaysXcheck,
} from "./modules/register";

import geolocation from "./modules/geolocation";
// import saveCard from "./modules/saveCard";
import callback from "./modules/callback";
import pullToRefresh from "./modules/pullToRefresh";
import qrCodeScanner from "./modules/qrCodeScanner";
import swModule from "./modules/swModule";
import {
  installAppPromptPopup,
  isPWA,
  offlineBadge,
  shareCard,
} from "./modules/misc";

// serviceWorker registration
swModule();

// Client side validation
formValidate(
  document.querySelector(
    "[action='/register'], [action='/account'], [action='/callback'], [action='/login'], #password-reset"
  )
);
formValidate(
  document.querySelector(
    "[action='/account/forgot'], [action^='/add/cardType']"
  )
);
callback(document.querySelector("[action='/callback']"));

// Localstorage for card users to save the cards
if (document.querySelector("#lyt-card")) {
  setLocalStorage();
}

// geolocation for create card form
geolocation(document.querySelectorAll(".form-create-card, .form-update-card"));

// phone mask for register/account page
phoneMask(document.querySelector("#phone"));

// QR code color visual for register/account page
qrcodeColorEditor(
  document.querySelector("#qrcodeColor"),
  document.querySelector("#qrcodeBGColor"),
  document.querySelectorAll("svg.qrCodeEl")
);

// Single bouns icon check
// singleBonusIcon(document.querySelector("#singleBonusIcon"));

// Show/hide password text
showHide(document.querySelectorAll(".btn-show-hide"));

// google maps loaded after the bundle but still for some reason code runs before google initialized
if (document.querySelector("input[id^='location']")) {
  setTimeout(() => {
    locationAutocomplete(
      document.querySelectorAll("input[id^='location']"),
      document.querySelectorAll("input[id^='address']"),
      document.querySelectorAll("input[id^='mapurl']"),
      document.querySelectorAll("input[id^='pid']"),
      document.querySelectorAll("input[id^='coordinates_lat']"),
      document.querySelectorAll("input[id^='coordinates_lng']")
    );
  }, 500);

  addAnAddress(document.querySelector(".add-address"));
  clearAddressFields(document.querySelectorAll("a.remove-field"));
}

// Save card as an image
// saveCard(document.querySelector("#download-card"));

// Saturdays cross-check to avoid both been checked
saturdaysXcheck(
  document.querySelector("#wd_субота"),
  document.querySelector("#we_субота")
);

// card list script to display saved cards from localstorage on /my-cards page
cardsList();

// Pull to refresh (PWA) for iOS saved web page to homescreen only
pullToRefresh(document.querySelector("#card"));

// QR-code scanner
qrCodeScanner(document.querySelector("#scan-qr-code"));

// Offline badge
// offlineBadge(
//   document.querySelector("#offline-badge")
// );

// Share card
shareCard(document.querySelector("#share-card"));

// Add to homescreen popup
installAppPromptPopup(document.querySelector("#install-app"));

// Set isPWA cookie
isPWA();
