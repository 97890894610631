import axios from "axios";

async function isLoggedIn() {
  try {
    const response = await axios.get("/api/account");
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function cardData() {
  const urlPathName = location.pathname;

  try {
    const response = await axios.get(`/api${urlPathName}`);

    if (!response.data) {
      return;
    } else {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
}

// Add localStorage logic here
const setLocalStorage = async function () {
  const loggedInPromise = isLoggedIn();
  const cardDataPromise = cardData();

  const [loggedIn, card] = await Promise.all([
    loggedInPromise,
    cardDataPromise,
  ]);

  if (loggedIn) return;

  // if (card.discarded) {
  //   const cardsData = JSON.parse(localStorage.getItem("cardsData")) || null;

  //   return;
  // }

  const cardsData = JSON.parse(localStorage.getItem("cardsData")) || [];

  const cardDetailsObj = {
    cardStoreId: card.author._id,
    cardStoreName: card.author.name,
    cardStoreSlug: card.author.slug,
    cardStoreLogo: card.author.storeLogo ? card.author.storeLogo : null,
    cards: [
      {
        cardId: card._id,
        cardTypeId: card.cardType._id,
        cardTypeName: card.cardType.name,
        cardAmount: card.amount,
        cardBonusAmount: card.cardType.bonusAmount,
        cardBonusIcon: card.cardType.card.bonusEarnedIcon,
      },
    ],
  };

  function findObjectById(arr, id) {
    return arr.find((item) => item.cardStoreId === id);
  }

  function findCardByTypeId(arr, id) {
    return arr.find((card) => card.cardTypeId === id);
  }

  function updateCardsDataArray(arr, obj) {
    const existingObj = findObjectById(arr, obj.cardStoreId);

    if (existingObj) {
      localStorage.removeItem("cardsData");

      const existingCardType = findCardByTypeId(
        existingObj.cards,
        card.cardType._id
      );

      // console.log("Find existing card in cards arr:", existingCardType);
      // console.log("The card data:", card);

      if (existingCardType) {
        // check IF existingObj.cards has specific card ID (Array.find())
        // then change that card for a new one
        existingCardType.cardId = card._id;
        existingCardType.cardTypeName = card.cardType.name;
        existingCardType.cardAmount = card.amount;
        existingCardType.cardBonusAmount = card.cardType.bonusAmount;
      } else {
        // ELSE push new card into existingObj.cards Array
        existingObj.cards.push({
          cardId: card._id,
          cardTypeId: card.cardType._id,
          cardTypeName: card.cardType.name,
          cardAmount: card.amount,
          cardBonusAmount: card.cardType.bonusAmount,
          cardBonusIcon: card.cardType.card.bonusEarnedIcon,
        });
      }

      existingObj.cardStoreName = obj.cardStoreName;
      existingObj.cardStoreSlug = obj.cardStoreSlug;
      existingObj.cardStoreLogo = obj.cardStoreLogo;
      localStorage.setItem("cardsData", JSON.stringify(cardsData));
    } else {
      localStorage.removeItem("cardsData");
      arr.push(obj);
      localStorage.setItem("cardsData", JSON.stringify(cardsData));
    }
  }

  updateCardsDataArray(cardsData, cardDetailsObj);
};

const checkLocalStorage = function () {
  if (localStorage.hasOwnProperty("cardsData")) {
    const cardsData = JSON.parse(localStorage.getItem("cardsData"));

    return cardsData;
  }
};

const removeCardFromLocalStorage = async function (cardId, storeId) {
  if (confirm("Ви впевненні, що бажаєте позбутися цієї картки?")) {
    // First set the card in a DB as a discarded
    try {
      await axios.post(`/api/discard/${cardId}`);
    } catch (error) {
      console.log(error);
    }

    // Then update localstorage
    const cardsData = JSON.parse(localStorage.getItem("cardsData"));

    cardsData.forEach((cardDataObj) => {
      if (cardDataObj.cardStoreId === storeId) {
        cardDataObj.cards = cardDataObj.cards.filter(
          (card) => card.cardId !== cardId
        );
      }
      // Remove an Object entirely from cardsData Array if no cards
      if (!cardDataObj.cards.length) {
        cardsData.pop(cardDataObj);
      }
    });

    localStorage.setItem("cardsData", JSON.stringify(cardsData));

    location.reload();
  }
};

export {
  isLoggedIn,
  setLocalStorage,
  checkLocalStorage,
  removeCardFromLocalStorage,
};
